import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { UserContext } from '@/application/Providers/AuthProvider'
import { LoadingScreen } from '@/components/LoadingScreen'

export const Page: React.FC = () => {
  const { asPath, replace, pathname } = useRouter()
  const { user } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)

  // Next produces a MPA
  // In order for Amplify to know the correct page to route you to, we need to setup
  // redirects via the Amplify console which is tricky to maintain
  // https://docs.amplify.aws/guides/hosting/nextjs/q/platform/js/#dynamic-routes
  //
  // The app is instead setup like a SPA, so the index page needs to figure out which
  // URL you wanted, then redirect you to that place. It's a bit of a hack but easier
  // than maintaining redirects in the Amplify console (for now)

  useEffect(() => {
    const handleRouting = async () => {
      if (!user.givenName) {
        setIsLoading(true)
        return
      }
      setIsLoading(false)
      if (asPath === '/' && pathname === '/') {
        if (user.returningUser === 'true') {
          replace('/my-account')
        } else {
          replace('/broker-selection')
        }
      } else {
        replace(asPath)
      }
    }
    handleRouting()
    // removed replace as a dependency as it was causing infinite rerender in
    // conjunction with MSAL redirect when code appended to the URL
  }, [asPath, pathname, user])

  if (isLoading) {
    return <LoadingScreen />
  }

  return <></>
}

export default Page
