import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Body1 } from '@tumelo/designsystem'
import { LoadingAnimation } from './LoadingAnimation'

interface Props {
  loadingText?: string
}

export const LoadingScreen: React.FC<Props> = ({ loadingText }) => {
  const { colors } = useTheme()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <StyledOverlay>
      <ContentWrapper>
        <LoadingAnimation />
        {loadingText && (
          <TextWrapper>
            <Body1 color={colors.white}>{loadingText}</Body1>
          </TextWrapper>
        )}
      </ContentWrapper>
    </StyledOverlay>
  )
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const TextWrapper = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 4rem;
  width: 230px;
  text-align: center;
`
