import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import styled from 'styled-components'

export const LoadingAnimation: React.FC = () => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: container.current as HTMLDivElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/loadingAnimationWithBg.json',
    })

    return () => anim.destroy()
  }, [])

  return <StyledDiv ref={container} />
}

const StyledDiv = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 14px;
  overflow: hidden;
`
